import React, { useState } from "react";

import CustomFeedback from "@/app/components/data-display/CustomFeedback";
import { Button } from "@/app/components/ui/button";
import { Icon } from "@/app/components/ui/icons/icon";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger } from
"@/app/components/ui/tooltip";

import conversationStyles from "../layout/Conversation.module.css";

const negativeFeedbackContent = [
{ label: `Not helpful`, id: `not-helpful` },
{ label: `Inappropriate`, id: `inappropriate` },
{ label: `Other`, id: `other` }];

const positiveFeedbackContent = [
{ label: `I intend to follow this advice`, id: `intent-advice` },
{ label: `Thanks, I'll consider it`, id: `consider-advice` },
{ label: `Other`, id: `other` }];


export type FeedbackButtonsProps = {
  messageId: string;
};

const FeedbackButtons = ({ messageId }: FeedbackButtonsProps) => {
  const [isPositiveOpen, setIsPositiveOpen] = useState(false);
  const [isNegativeOpen, setIsNegativeOpen] = useState(false);

  const positiveFeedbackOpen = () => {
    setIsPositiveOpen(!isPositiveOpen);
  };

  const negativeFeedbackOpen = () => {
    setIsNegativeOpen(!isNegativeOpen);
  };

  return (
    <div className={conversationStyles.feedback}>
			<div
        dir="ltr"
        className="flex items-center hover:bg-color-gray-200 rounded-s-lg"
        data-testid="FeedbackButtons-Positive">

				<TooltipProvider delayDuration={0}>
					<Tooltip>
						<TooltipTrigger asChild>
							<Button
                id="Feedback__open-positive-button"
                variant="icon"
                size="sm"
                group="left"
                type="button"
                shape="rounded"
                onClick={positiveFeedbackOpen}>

								<Icon name="thumb-up" size="xs" />
							</Button>
						</TooltipTrigger>
						<TooltipContent className="bg-blue-100 text-white">
							Good response
						</TooltipContent>
					</Tooltip>
				</TooltipProvider>
			</div>

			<div
        dir="rtl"
        className="flex items-center hover:bg-color-gray-200 rounded-s-lg"
        data-testid="FeedbackButtons-Negative">

				<TooltipProvider delayDuration={0}>
					<Tooltip>
						<TooltipTrigger asChild>
							<Button
                id="Feedback__open-negative-button"
                variant="icon"
                size="sm"
                group="right"
                shape="rounded"
                onClick={negativeFeedbackOpen}
                type="button">

								<Icon name="thumb-down" size="xs" />
							</Button>
						</TooltipTrigger>
						<TooltipContent className="bg-blue-100 text-white">
							Bad response
						</TooltipContent>
					</Tooltip>
				</TooltipProvider>
			</div>
			<CustomFeedback
        isOpen={isNegativeOpen}
        onClose={negativeFeedbackOpen}
        title="Help us get better"
        subtitle="Provide additional feedback on this answer. Select all that apply."
        content={negativeFeedbackContent}
        optionalText="How can response effectiveness be improved?"
        messageId={messageId}
        isPositive={false} />

			<CustomFeedback
        isOpen={isPositiveOpen}
        onClose={positiveFeedbackOpen}
        title="Feedback"
        content={positiveFeedbackContent}
        optionalText="Please provide details:"
        isFeedbackOptional
        messageId={messageId}
        isPositive />

		</div>);

};

export default FeedbackButtons;