import { z } from "zod"

export const MessageRatingSchema = z.object({
	id: z.string(),
	type: z.string(),
	metadata: z.array(z.string()),
	comment: z.string(),
	messageId: z.string(),
	createdAt: z.string(),
	updatedAt: z.string(),
})

const MessageRatingTypeEnum = z.enum([`positive`, `negative`])

export const MessageRatingEditorSchema = z
	.object({
		type: MessageRatingTypeEnum,
		metadata1: z.string().optional(),
		metadata2: z.string().optional(),
		metadata3: z.string().optional(),
		comment: z.string().optional(),
		messageId: z.string({ required_error: `This field is required` }),
	})
	.refine(
		(data) =>
			data.metadata1 ?? data.metadata2 ?? data.metadata3 ?? data.comment,
		`Either field should be filled in.`,
	)
