import { Form, useFetcher } from "@remix-run/react";
import { getState, setState } from "atom.io";
import { findState } from "atom.io/ephemeral";
import type { ReactNode } from "react";
import { useCallback, useEffect, useRef, useState } from "react";

import { Button } from "@/app/components/ui/button";
import { ChatMessageRoleEnum } from "@/app/utils/enums/ChatMessageRoleEnum";

import { Icon } from "../ui/icons/icon";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger } from
"../ui/tooltip";
import chatBoxStyles from "./ChatBox.module.css";
import {
  messageAtoms,
  messageIndexAtoms,
  scrollConversationContainerToBottom } from
"./Conversation";

type ChatBoxProps = {
  chatId: string;
  submitDisabled?: boolean;
};

const fetcherKey = `chat-fetcher-key`;

const ChatBox = ({
  chatId,
  submitDisabled: _submitDisabled
}: ChatBoxProps): ReactNode => {
  const formRef = useRef<
    HTMLFormElement & {elements: {userMessage: HTMLTextAreaElement;};}>(
    null);
  const [textareaValue, setTextareaValue] = useState<string>(``);
  const fetcher = useFetcher({ key: fetcherKey });

  const handleTextareaChange = (
  event: React.ChangeEvent<HTMLTextAreaElement>) =>
  {
    setTextareaValue(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === `Enter` && !event.shiftKey) {
      event.preventDefault();
      const { preventSubmit } = handleSubmit();
      if (!preventSubmit) {
        fetcher.submit(formRef.current);
      }
    }
  };

  const [forceDisableSubmit, setForceDisableSubmit] = useState(false);
  const submitDisabled = !!_submitDisabled || forceDisableSubmit;
  useEffect(() => {
    if (_submitDisabled && forceDisableSubmit) {
      setForceDisableSubmit(false);
      window.clearTimeout(submitDisableTimerRef.current);
    }
    return () => {
      window.clearTimeout(submitDisableTimerRef.current);
    };
  }, [_submitDisabled, forceDisableSubmit]);
  const submitDisableTimerRef = useRef<number | undefined>(undefined);
  const disableSubmitTimer = useCallback(() => {
    setForceDisableSubmit(true);
    submitDisableTimerRef.current = window.setTimeout(() => {
      setForceDisableSubmit(false);
    }, 3000);
  }, []);

  const handleSubmit = useCallback(() => {
    if (submitDisabled) {
      return { preventSubmit: true };
    }

    const newMessageText = formRef.current?.elements.userMessage?.value;
    if (!newMessageText) {
      return { preventSubmit: true };
    }
    const messageIndex = findState(messageIndexAtoms, chatId);
    const tempNewMessageId = `TEMP-${getState(messageIndex).length}`;
    const messageState = findState(messageAtoms, tempNewMessageId);
    setState(
      messageState,
      (prev) =>
      ({
        ...prev,
        id: tempNewMessageId,
        role: ChatMessageRoleEnum.user,
        content: newMessageText
      }) as const
    );
    setState(messageIndex, (prev) => {
      return [...prev, tempNewMessageId];
    });

    disableSubmitTimer();
    scrollConversationContainerToBottom();
    setTextareaValue(``);
    return { preventSubmit: false };
  }, [submitDisabled, chatId, disableSubmitTimer]);

  return (
    <Form
      action={`/chat/${chatId}`}
      method="POST"
      navigate={false}
      onSubmit={(e) => {
        const { preventSubmit } = handleSubmit();
        if (preventSubmit) {
          e.preventDefault();
        }
      }}
      ref={formRef}
      className={chatBoxStyles.form}>

			<div className={chatBoxStyles[`chat-box`]}>
				<div className={chatBoxStyles[`chat-inner`]}>
					<div className={chatBoxStyles[`chat-spacer`]}>
						{textareaValue?.
            split(`\n`)
            // biome-ignore lint/suspicious/noArrayIndexKey: index is all we've got to work with
            .map((t, i) => t ? <div key={t}>{t}</div> : <br key={i} />)}
					</div>
					<textarea
            name="userMessage"
            placeholder="Ask anything..."
            onChange={handleTextareaChange}
            data-testid="ChatBox-input-textarea"
            onKeyDown={handleKeyDown}
            value={textareaValue} />

				</div>
				<TooltipProvider delayDuration={0}>
					<Tooltip>
						<TooltipTrigger asChild>
							<Button
                disabled={submitDisabled}
                id="CHAT__chatbox-submit-button"
                variant="iconCtaSecondary"
                size="fullHeight"
                aria-disabled={submitDisabled}
                data-testid="ChatBox-button-submit"
                className={chatBoxStyles.send}
                type="submit">

								<Icon name="arrow-send" size="xl" />
							</Button>
						</TooltipTrigger>
						<TooltipContent className="bg-blue-100 text-white">
							Send message
						</TooltipContent>
					</Tooltip>
				</TooltipProvider>
			</div>
		</Form>);

};

export default ChatBox;