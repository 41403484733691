import { getFormProps, getTextareaProps, useForm } from "@conform-to/react"
import { getZodConstraint, parseWithZod } from "@conform-to/zod"
import { Form } from "@remix-run/react"
import React, { useState } from "react"

import { Button } from "@/app/components/ui/button"
import { Checkbox } from "@/app/components/ui/checkbox"
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
} from "@/app/components/ui/dialog"
import { Icon } from "@/app/components/ui/icons/icon"
import { Label } from "@/app/components/ui/label"
import { useIsPending } from "@/app/utils/misc"
import { MessageRatingEditorSchema } from "@/app/utils/schemas/MessageRating"

import { ErrorList, TextareaField } from "../forms"

interface ContentItem {
	label: string
	id: string
}
interface CustomFeedbackProps {
	isOpen: boolean
	onClose: () => void
	title: string
	subtitle?: string
	content: ContentItem[]
	optionalText?: string
	isFeedbackOptional?: boolean
	messageId: string
	isPositive: boolean
}

const CustomFeedback: React.FC<CustomFeedbackProps> = ({
	isOpen,
	title,
	subtitle,
	content,
	optionalText,
	onClose,
	isFeedbackOptional,
	messageId,
	isPositive,
}) => {
	const isPending = useIsPending()
	const [selectedItems, setSelectedItems] = useState<string[]>([])
	const boxIsChecked = (id: string) => {
		setSelectedItems((prev) =>
			prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id],
		)
	}

	const onDialogClose = () => {
		onClose()
		setSelectedItems([``])
	}

	const [form, fields] = useForm({
		id: `feedback-editor`,
		constraint: getZodConstraint(MessageRatingEditorSchema),
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: MessageRatingEditorSchema })
		},
		onSubmit: onDialogClose,
	})

	return (
		<Dialog
			defaultOpen
			open={isOpen}
			onOpenChange={onDialogClose}
			data-testid="CustomFeedback"
		>
			<DialogContent variant="default" size="medium">
				<Form method="POST" {...getFormProps(form)}>
					<DialogHeader>
						<h3 data-testid="Feedback-title">{title}</h3>
						<p>{subtitle}</p>
						<Button
							id="FEEDBACK__close-button"
							variant="icon"
							type="button"
							onClick={onDialogClose}
						>
							<Icon name="close" size="lg" />
						</Button>
					</DialogHeader>
					<hr className="bg-color-background" />
					{
						<input
							type="hidden"
							name={fields.messageId.name}
							value={messageId}
						/>
					}
					{
						<input
							type="hidden"
							name={fields.type.name}
							value={isPositive ? `positive` : `negative`}
						/>
					}
					<div className="flex flex-col gap-[16px] px-[32px] py-[24px]">
						<div className="md:flex md:flex-wrap grid grid-rows-3 gap-4">
							{content.map((item, index) => (
								<div
									key={item.id}
									className={`flex items-center md:justify-between h-8 gap-2 rounded-full py-1 px-2  ${
										selectedItems.includes(item.id)
											? `bg-color-teal-300 text-white font-bold border border-solid border-color-gray-600`
											: `bg-color-background border border-solid border-color-gray-600`
									}`}
								>
									<Checkbox
										id={item.id}
										value={item.label}
										name={`metadata${index + 1}`}
										onCheckedChange={() => {
											boxIsChecked(item.id)
										}}
									/>
									<Label
										className="text-xs font-light cursor-pointer"
										htmlFor={item.id}
									>
										{item.label}
									</Label>
								</div>
							))}
						</div>
						<div className="flex flex-col gap-2">
							<div className="flex justify-between text-xs">
								<p className="font-medium">{optionalText}</p>
								{isFeedbackOptional ? (
									<p className="font-light text-color-gray-700">optional</p>
								) : (
									<p className="font-light text-color-gray-700">required</p>
								)}
							</div>
							<TextareaField
								labelProps={{ children: `Comment`, hidden: true }}
								textareaProps={{
									...getTextareaProps(fields.comment, {
										ariaAttributes: true,
									}),
								}}
								errors={fields.comment.errors}
							/>

							{form.errors && (
								<ErrorList id={form.errorId} errors={form.errors} />
							)}
						</div>
					</div>
					<DialogFooter className="gap-2 py-4 px-6 bg-color-background">
						<div className="flex gap-3 justify-end">
							<Button
								id="Feedback__cancel-button"
								variant="ghost"
								size="normal"
								type="button"
								onClick={onDialogClose}
							>
								Cancel
							</Button>
							<Button
								form={form.id}
								id="Feedback__submit-button"
								variant="cta"
								size="normal"
								type="submit"
								disabled={isPending || !isOpen}
								name="intent"
								value="feedback"
							>
								Submit
							</Button>
						</div>
					</DialogFooter>
				</Form>
			</DialogContent>
		</Dialog>
	)
}

export default CustomFeedback
